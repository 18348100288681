/**
 * @description 根节点动态fontSize
 */
(function(doc, win) {
  // 判断旋转
  var movingFlag = false;
  // 判断横屏
  var isLandscapeFlag = false;
  // 更新根节点字体大小
  function _updateBaseFontSize() {
    var clientWidth = doc.documentElement.clientWidth;
    var clientHeight = doc.documentElement.clientHeight;
    var scale = 14;
    if (isLandscapeFlag && clientHeight < 430) {
      scale = clientHeight / 10;
    } else {
      scale = (clientWidth > 540 ? 540 : clientWidth) / 10;
    }
    doc.documentElement.style.fontSize = scale + "px";
  }
  // 处理设备旋转方向
  function handleOrientationChange() {
    movingFlag = true;
    var supportOrientation =
      typeof win.orientation === "number" &&
      typeof win.onorientationchange === "object";
    var deg = 0;
    if (supportOrientation) {
      deg = Math.abs(win.orientation);
    } else {
      if (window.innerWidth > window.innerHeight) {
        deg = 90;
      }
    }
    if (deg === 90) {
      isLandscapeFlag = true;
    } else {
      isLandscapeFlag = false;
    }
    _updateBaseFontSize();
  }
  // 处理窗口改变
  function handleScreenResize() {
    if (movingFlag) {
      movingFlag = false;
      return;
    }
    _updateBaseFontSize();
  }
  // 初始化
  function init() {
    handleOrientationChange();
  }
  win.addEventListener("orientationchange", handleOrientationChange, false);
  win.addEventListener("resize", handleScreenResize, false);
  init();
})(document, window);
